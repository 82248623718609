<template>
  <div>
    <bj-modal
      :class="$style.group"
      :visible="visible"
      :width="800"
      title="选择表单"
      :body-style="{ height: '500px', 'max-height': '500px', position: 'relative', 'overflow-y': 'auto' }"
      @cancel="cancel"
    >
      <a-table :columns="columns" :data-source="data" :loading="loading" :pagination="false" row-key="id">
        <template #choose="item">
          <div :class="$style.choose">
            <a-checkbox
              v-if="multiple"
              :checked="selectedRowKeys.indexOf(item.id) !== -1"
              @change="onChange(item.id, item)"
            />
            <a-radio v-else :checked="selectedRowKeys.indexOf(item.id) !== -1" @click="onChange(item.id, item)" />
          </div>
        </template>
        <template #status="item">
          <BjTag :type="item.mode === 1 ? 'primary' : 'default'">{{ item.model === 1 ? '登记' : '满额回收' }}</BjTag>
        </template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="page"
          show-size-changer
          :show-total="total => `共 ${total} 条`"
          :page-size.sync="pageSize"
          :total="total"
          @showSizeChange="onShowSizeChange"
          @change="onPageChange"
        />
      </div>
      <template #footer>
        <a-row>
          <a-col class="text-left" span="12" />
          <a-col class="text-right" span="12">
            <BjButton @click="cancel()">取消</BjButton>
            <BjButton type="primary" @click="getChoose()">确定</BjButton>
          </a-col>
        </a-row>
      </template>
    </bj-modal>
  </div>
</template>

<script>
import { appActivityManage } from '@/service'

const service = new appActivityManage()

export default {
  name: 'Home',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    select: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: [],
      selectedRowKeys: [],
      selectData: [],
      loading: false,
      pageSize: 20,
      page: 1,
      total: 0,
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '',
          width: 80,
          scopedSlots: {
            customRender: 'choose',
          },
        },
        { title: '表单名称', dataIndex: 'name', width: 150, ellipsis: true },
        {
          title: '表单模式',
          dataIndex: 'mode',
          customRender: value => {
            switch (value) {
              case 1:
                return '登记模式'
              case 2:
                return '预订模式'
            }
          },
        },
        {
          title: '回收方式',
          dataIndex: 'recycle_type',
          customRender: value => {
            switch (value) {
              case 0:
                return '满额回收'
              case 1:
                return '定时回收'
              case 2:
                return '定时满额'
            }
          },
        },
        { title: '添加时间', dataIndex: 'created_at' },
      ]
    },
  },
  watch: {
    visible(val) {
      if (val) {
        if (this.select && this.select.length) {
          this.select.map(({ id }) => {
            this.selectedRowKeys.push(id)
          })
        } else {
          this.selectedRowKeys = []
        }
        this.getList()
      } else {
        this.init()
      }
    },
  },
  methods: {
    async getList() {
      try {
        this.loading = true
        const { data } = await service.getForm({
          page: this.page,
          page_size: this.pageSize,
        })
        this.data = data.record
        this.total = data.total
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    getChoose() {
      if (this.selectedRowKeys.length) {
        let tmp = []
        this.selectedRowKeys.map(id => {
          for (let index = 0; index < this.selectData.concat(this.select).length; index++) {
            if (id === this.selectData.concat(this.select)[index].id) {
              tmp.push(this.selectData.concat(this.select)[index])
              break
            }
          }
        })
        this.$emit('change', tmp)
      }
      this.$emit('update:visible', false)
    },
    cancel() {
      this.$emit('update:visible', false)
    },
    onChange(id, item) {
      if (this.multiple) {
        if (this.selectedRowKeys.indexOf(id) === -1) {
          this.selectedRowKeys.push(id)
          this.selectData.push(item)
        } else {
          this.selectedRowKeys = this.selectedRowKeys.filter(item => item !== id)
        }
      } else {
        this.selectedRowKeys = []
        this.selectedRowKeys.push(id)
        this.selectData = []
        this.selectData.push(item)
      }
    },
    init() {
      this.selectedRowKeys = []
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
  },
}
</script>

<style lang="less" module>
.group {
  .cover {
    width: 32px;
    height: 32px;
    object-fit: cover;
  }

  .select {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 2px;
    color: #fff;
    line-height: 20px;
    text-align: center;
    background: @primary-color;
    border-radius: 10px;
  }

  .foot {
    position: absolute;
    right: 20px;
    bottom: 10px;
  }
}
</style>
