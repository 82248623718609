<template>
  <div :class="$style.home">
    <div id="box" :class="$style.base">
      <p :class="$style.title">基础信息</p>
      <div :class="$style.box">
        <div class="layout">
          <div class="label">
            <span class="required">*</span>
            活动名称
          </div>
          <div class="value">
            <BjInput v-model="add.name" placeholder="请输入活动名称" />
          </div>
        </div>

        <div class="layout">
          <div class="label">
            <span class="required">*</span>
            活动封面
          </div>
          <div class="value">
            <selectMaterial type="multiple" :data="add.cover" :allow="['image']" @change="coverChange" />
            <p class="tip mt-10">封面图最多上传10张，建议尺寸670px*600px；大小不超过2M；格式支持JPG、PNG。</p>
          </div>
        </div>

        <div class="layout mb-20">
          <div class="label">
            <span class="required">*</span>
            活动摘要
          </div>
          <div class="value" :class="$style.area">
            <a-textarea
              v-model="add.summary"
              :auto-size="{ minRows: 6, maxRows: 6 }"
              placeholder="请输入活动的摘要内容。"
              :max-length="60"
            />
            <span :class="$style.num">{{ add.summary ? add.summary.length : 0 }}/60</span>
          </div>
        </div>

        <div class="layout">
          <div class="label">
            <span class="required">*</span>
            联系电话
          </div>
          <div :class="$style.antFrom" class="value">
            <BjInput v-model="add.contact_tel" placeholder="请输入活动方的联系电话" />
            <p class="tip">联系电话设置仅支持数字和符号“-”，请注意检查，以免用户无法直接点击拨打。</p>
          </div>
        </div>

        <div class="layout">
          <div class="label">
            <span class="required">*</span>
            选择位置
          </div>
          <div class="value" :class="$style.location">
            <BjInput v-model="add.location" placeholder="请选择活动地点额位置">
              <div slot="suffix">
                <a-popover title="位置信息">
                  <template slot="content">
                    <p>经度：{{ add.longitude }}</p>
                    <p>纬度：{{ add.latitude }}</p>
                    <p>地址：{{ add.real_location }}</p>
                  </template>
                  <i v-if="add.real_location" style="vertical-align: -6px" class="ri-question-line location-icon" />
                </a-popover>
                <span :class="$style.locationbtn" @click="onMapSelect()"><i class="ri-map-pin-line" />选择位置</span>
              </div>
            </BjInput>
            <p class="tip mb-20">请在地图上标注活动地点的位置，尽量准确。用户可通过此地址进行位置查看或导航等。</p>
          </div>
        </div>

        <div class="layout">
          <div class="label">
            <span class="required">*</span>
            活动时间
          </div>
          <div class="value">
            <a-range-picker
              v-model="date"
              style="width: 100%"
              class="mb-10"
              :show-time="{ format: 'HH:mm:ss' }"
              format="YYYY-MM-DD HH:mm:ss"
              :placeholder="['活动开始时间', '活动结束时间']"
            />
            <p class="tip">
              请设置活动的开始和结束时间，活动时间将影响活动的状态，活动结束后用户将不能参与活动报名等。
            </p>
          </div>
        </div>

        <div class="layout">
          <div class="label">
            <span class="required">*</span>
            所属分组
          </div>
          <div class="value">
            <BjSelect
              v-model="add.group_id"
              key-field="id"
              inner-search
              placeholder="请选择所属分组"
              value-field="id"
              label-field="name"
              show-all
              :options="groupData"
            />
          </div>
        </div>

        <div class="layout">
          <div class="label">
            <span class="required">*</span>
            发布状态
          </div>
          <div class="value line-40">
            <a-radio-group v-model="add.status">
              <a-radio :value="1"> 已发布 </a-radio>
              <a-radio :value="2"> 未发布 </a-radio>
            </a-radio-group>
            <p class="tip">已发布的活动用户才能访问，未发布的活动仅可预览。</p>
          </div>
        </div>
      </div>
    </div>

    <div :class="$style.desc">
      <p :class="$style.title">活动介绍</p>
      <BjEditor ref="editor" v-model="add.content" placeholder="请在这里输入活动介绍。" />
    </div>
    <div :class="$style.set">
      <p :class="$style.title">报名配置</p>
      <div class="layout">
        <div class="label">
          <span class="required">*</span>
          是否报名
        </div>
        <div class="value line-40">
          <a-radio-group v-model="add.is_sign">
            <a-radio :value="0"> 无需报名 </a-radio>
            <a-radio :value="1"> 需要报名 </a-radio>
          </a-radio-group>
          <p class="tip mt-10">请设置活动是否需要用户报名参加，如果需要，请选择对应的报名表单。</p>
        </div>
      </div>
      <div v-if="add.is_sign" class="layout">
        <div class="label">
          <span class="required">*</span>
          报名表单
        </div>
        <div class="value">
          <div class="line-40">
            <BjButton @click="onAddForm()">
              <i class="ri-edit-line left" />
              选择表单
            </BjButton>
          </div>
          <p v-if="add.form.label" class="mt-10">
            已选择：
            <span>{{ add.form.label }}</span>
          </p>
        </div>
      </div>
    </div>

    <div :class="$style.footer" :style="{ width: footWidth }">
      <BjButton type="primary" @click="onSave()">
        <i class="ri-send-plane-2-line left" />
        立即发布
      </BjButton>
      <BjButton class="ml-10" @click="onCannel()">取消发布</BjButton>
    </div>
    <chooseForm :visible.sync="visible.form" :select="selectForm" @change="formChange" />
    <BjMap :visible.sync="visible.map" @change="mapChange" />
  </div>
</template>

<script>
import moment from 'moment'

import BjEditor from '@/components/BjEditor'
import BjMap from '@/components/BjMap.vue'
import selectMaterial from '@/components/selectMaterial'
import { appActivityManage } from '@/service'

import chooseForm from './chooseForm.vue'

const service = new appActivityManage()

export default {
  name: 'Home',
  components: {
    selectMaterial,
    BjEditor,
    chooseForm,
    BjMap,
  },
  data() {
    return {
      visible: {
        form: false,
        map: false,
      },
      add: {
        id: '',
        name: null,
        cover: [],
        summary: null,
        contact_tel: null,
        end_time: null,
        start_time: null,
        location: null,
        real_location: null,
        longitude: null,
        latitude: null,
        adcode: null,
        group_id: [],
        status: 1,
        content: null,
        is_sign: 0,
        form: {},
      },
      date: [],
      groupData: [],
      footWidth: '800px',
      id: null,
      selectForm: [],
    }
  },
  mounted() {
    this.footWidth = document.getElementById('box').offsetWidth + 'px'
    window.onresize = () => {
      this.footWidth = document.getElementById('box').offsetWidth + 'px'
    }
  },
  created() {
    this.getGroup()
    this.id = this.$route.query.id || null
    if (this.id) {
      this.getInfo()
    }
  },
  methods: {
    async getGroup() {
      try {
        const { data } = await service.getGroup()
        this.groupData = data
      } catch (e) {}
    },
    async getInfo() {
      const { data } = await service.getInfo({
        id: this.id,
      })
      this.add = data
      this.date = [moment(data.start_time), moment(data.end_time)]
      this.selectForm = [data.form.params.id]
    },
    coverChange(data) {
      if (data.length > 10) {
        this.$message.error('最多添加10张')
        return
      }
      this.add.cover = data
    },
    async onSave() {
      try {
        // this.add.start_time = this.date[0].format('YYYY-MM-DD HH:mm:ss')
        // this.add.end_time = this.date[1].format('YYYY-MM-DD HH:mm:ss')
        const param = {
          ...this.add,
          start_time: this.date.length ? this.date[0].format('YYYY-MM-DD HH:mm:ss') : null,
          end_time: this.date.length ? this.date[1].format('YYYY-MM-DD HH:mm:ss') : null,
        }
        if (this.add.id) {
          await service.onEdit(param)
        } else {
          await service.onAdd(param)
        }
        this.$message.success(this.add.id ? '编辑成功' : '添加成功')
        this.$router.push({
          name: 'appActivityManage',
        })
      } catch (e) {}
    },
    onCannel() {
      this.$router.push({
        name: 'appActivityManage',
      })
    },
    onAddForm() {
      this.visible.form = true
    },
    onMapSelect() {
      this.visible.map = true
    },
    formChange(data) {
      this.add.form = {
        type: 'form',
        label: data[0].name,
        params: {
          id: data[0].id,
        },
      }
      this.selectForm = [data[0].id]
    },
    mapChange(e) {
      this.add.location = e.location
      this.add.real_location = e.location
      this.add.longitude = e.longitude
      this.add.latitude = e.latitude
      this.add.adcode = e.adcode
    },
  },
}
</script>

<style lang="less" module>
.home {
  .base {
    padding: 20px;
    background: #fff;

    .area {
      position: relative;

      .num {
        position: absolute;
        right: 10px;
        bottom: 10px;
        color: #5c5c5c;
      }
    }

    .location {
      :global {
        .ant-form-item {
          margin-bottom: 5px !important;
        }

        .ant-input {
          padding-right: 125px !important;
        }
      }
    }

    .locationbtn {
      display: inline-block;
      width: 84px;
      height: 28px;
      color: #fff;
      font-size: 12px;
      line-height: 28px !important;
      text-align: center;
      background: @primary-color;
      border-radius: 6px;
      cursor: pointer;

      i {
        margin-right: 3px;
        color: #fff;
        font-size: 16px;
        vertical-align: -3px;
      }
    }
  }

  .desc {
    margin-top: 20px;
    padding: 20px;
    background: #fff;
  }

  .set {
    margin-top: 20px;
    margin-bottom: 60px;
    padding: 20px;
    background: #fff;
  }

  .box {
    width: 750px;
  }

  .title {
    color: #000;
    font-size: 16px;
  }

  .footer {
    position: fixed;
    bottom: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    width: 880px;
    height: 60px;
    padding: 0 20px;
    line-height: 60px;
    background: #fff;
    box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.05);
  }
}

.antFrom {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
</style>
